@keyframes show {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}

@keyframes hide {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}

@keyframes animatetop {
	from {
		top: -300px; 
		opacity: 0;
	}
	to {
		top: 0; 
		opacity: 1;
	}
}

@keyframes toRight {
	from {
		transform: translateX(-1000px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes toLeft {
	from {
		transform: translateX(1000px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes toTop {
	from {
		transform: translateY(1000px);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes wowAppearance {
	from {
		opacity: 0;
		transform: rotate(0) scale(0.1);
	}
	to {
		opacity: 1;
		transform: rotate(360deg) scale(1);
	}
}

@keyframes simpleAppearance {
	from {
		opacity: 0;
		transform: scale(0.1);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes opacityAppearance {
	from {
		opacity: 0;
		filter: saturate(0);
	}
	to {
		opacity: 1;
		filter: saturate(1);
	}
}





















