//BASE STYLE START
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html {
	font-size: 10px;
}
body {	
	color: $textColor;
	font-family: $fontReg;
	font-size: 1.6rem;
	overflow-x: hidden;
	height: 100%;
	
	.l-container {
		position: relative;
		@include container;
		height: 100%;
	}
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	
	a {
		color: white;
		text-decoration: none;
	}
	
	h1 {
		margin: 0;
		font-family: $fontReg;
		font-size: 4.2rem;
		line-height: 6.4rem;
	}
	
	h2 {
		font-family: $fontReg;
		font-size: 3.6rem;
		line-height: 2.8rem;
	}
	
	h3 {
		font-family: $fontReg;
		font-size: 3rem;
		line-height: 4.4rem;
	}
	
	p {
		color: rgba(0,0,0,1);
		font-family: $fontReg;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
	
}

.js-toRight {
	animation-name: toRight;
	animation-duration: 1.4s;
}
.js-toLeft {
	animation-name: toLeft;
	animation-duration: 1.4s;
}
.js-wowAppearance {
	animation-name: wowAppearance;
	animation-duration: 1.4s;
}
.js-simpleAppearance {
	animation-name: simpleAppearance;
	animation-duration: 1.4s;
	animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.js-opacityAppearance {
	animation-name: opacityAppearance;
	animation-duration: 2.4s;
}
.js-toTop {
	animation-name: toTop;
	animation-duration: 1.4s;
	animation-timing-function: ease-out;
}
.js-hidden {
	visibility: hidden;
}
.h-wrapper {
	display: block;
}


//BASE STYLE END


//header
.l-header {
	height: 98px;
}
.c-header {
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
}
.c-header__wrap-logo {
	display: flex;
	align-items: center;
	height: 100%;
}
.c-header__logo {
	display: inline-block;
	height: 18px;
}
.c-header__wrap-btns {
	display: flex;
	align-items: center;
	height: 100%;
}
.c-header__btn-callback {
	display: inline-block;
	width: auto;
	height: 54px;
	background-image: url('../img/phone_icon.png');
	background-repeat: no-repeat;
	background-position: 10px center;
	padding: 0 20px 0 50px;
	font-family: $fontBlack;
	line-height: 54px;
	color: $textColor;
	margin-right: 10px;
}
.c-header__btn-order {
	position: relative;
	display: inline-block;
	width: 206px;
	height: 54px;
	background-color: #000;
	padding-left: 86px;
	line-height: 54px;
	font-size: 1.4rem;
	text-transform: uppercase;
	&::before {
		position: absolute;
		display: block;
		width: 28px;
		height: 28px;
		content: '';
		left: 40px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url('../img/order_icon.png');
		background-repeat: no-repeat;
	}
}

//main-slider
.l-main-slider {
	padding-top: 6rem;
	margin-bottom: 13.2rem;
}
.c-main-slider {
	&::after {
		display: table;
		content: '';
		clear: both;
	}
}

//about
.l-about {
	margin-bottom: 13.2rem;
}
.c-about {
	&::after {
		display: table;
		content: '';
		clear: both;
	}
}
.c-about__aside {
	@include span(5 of 12 break);
	margin-left: span(2 wide);
}
.c-about__par {
	font-family: $fontReg;
	font-size: 2.4rem;
	line-height: 3.6rem;
	margin-bottom: 66px;
}
.c-about__list {
	@include span(3.5 of 5);
}
.c-about__list-item {
	position: relative;
	padding-left: 42px;
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
	margin-bottom: 30px;
	&::before {
		position: absolute; 
		left: 0;
		display: block;
		content: '';
		width: 26px;
		height: 26px; 
		background: #fff;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	&:nth-of-type(1) {
		&::before {
			background-image: url('../img/dew_point_icon.png');
		}
	}
	&:nth-of-type(2) {
		&::before {
			background-image: url('../img/baby_feet_icon.png');
		}
	}
	&:nth-of-type(3) {
		&::before {
			background-image: url('../img/washing_machine_icon.png');
		}
	}
	&:nth-of-type(4) {
		&::before {
			background-image: url('../img/slippery_floor_icon2.png');
		}
	}
	&:nth-of-type(5) {
		&::before {
			background-image: url('../img/beach_ball_icon.png');
		}
	}
}
.c-about__link {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
	color: $textColor;
	text-decoration: underline;
}
.c-about__img-wrap {
	@include span(3 of 12 last);
	margin-top: -20px;
	margin-right: span(0.5);
	img {
		width: 100%;
	}
}

//packing
.c-packing {
	width: 100%;
	margin-bottom: 13.2rem;
}
.c-packing__img {
	width: 100%;
}

//know how
.l-know-how {
	margin-bottom: 13.2rem;
}
.c-know-how {
	
}
.c-know-how__left-aside {
	@include span(first 5.5 of 12);
	img {
		width: 100%;
	}
}
.c-know-how__right-aside {
	@include span(last 6 of 12);
}
.c-know-how__text {
	font-family: $fontReg;
	font-size: 2.4rem;
	line-height: 3.6rem;
	margin-bottom: 5rem;
}
.c-know-how__list {
	
}
.c-know-how__list-item {
	position: relative;
	padding-left: 34px;
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
	margin-bottom: 1rem;
	&::before {
		position: absolute;
		left: 0;
		display: block;
		content: '';
		background: #fff;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	&:nth-of-type(1) {
		&::before {
			width: 17px;
			height: 20px;
			background-image: url('../img/spiral_bound_booklet_icon.png');
		}
	}
	&:nth-of-type(2) {
		&::before {
			width: 15px;
			height: 22px;
			background-image: url('../img/prize_icon.png');
		}
	}
}
.c-know-how__list-link {
	color: #000;
	text-decoration: underline;
}


//frizione
.l-frizione {
	width: 100%;
	background: rgb(228,235,191);
}
.c-frizione {
	background-image: url(../img/section-image2.jpg);
	background-repeat: no-repeat;
	background-size: contain;
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}
.c-frizione__aside {
	@include span(4.5 of 12 last);
	margin-right: span(0.6);
	margin-top: 6rem;
	margin-bottom: 16rem;
	padding: 34px;
	background-color: #fff;
}
.c-frizione__title {
	font-family: $fontBlack;
	font-size: 2.6rem;
	line-height: 3.6rem;
	margin-bottom: 18px;
}
.c-frizione__par {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
}

//technology
.l-technology {
	padding-top: 13.2rem;
	padding-bottom: 13.2rem;
	margin-bottom: 13.2rem;
	background: rgb(228,235,191);
}
.c-technology {
	
}
.c-technology__img-wrap {
	@include span(8 of 12);
	margin-left: span(0.5);
}
.c-technology__img {
	width: 100%;
}
.c-technology__aside {
	@include span(isolate 6 of 12);
	margin-left: span(5 wide);
	margin-top: -20rem;
	padding: 46px 30px;
	background-color: #fff;
}
.c-technology__title {
	font-family: $fontBlack;
	font-size: 4.8rem;
	line-height: 5.6rem;
	color: $textColor;
	margin-bottom: 30px;
}
.c-technology__par {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
}


//benefits
.l-benefits {
	margin-bottom: 13.2rem;
}
.c-benefits-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 12.8rem;
}
.c-benefits-header__item {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: span(3.6);
}
.c-benefits-header__item-icon {
	margin-right: 16px;
}
.c-benefits-header__item-title {
	font-family: $fontReg;
	font-size: 1.8rem;
	line-height: 2.8rem;
	color: $textColor;
}
.c-benefits-body {
	margin-bottom: 6rem;
	
	&::after {
		display: table;
		content: '';
		clear: both;
	}
}
.c-benefits-body__item {
	@include span(4.6 of 12);
	margin-left: span(1.4);
	
	&:first-of-type {
		margin-top: 6rem;
		
		.c-benefits-body__article {
			margin-top: -18rem;
			margin-left: span(1.5);
		}
	}
	&:last-of-type {
		
		.c-benefits-body__article {
			width: span(9.6);
			margin-top: -18rem;
			margin-left: span(-1.5);
		}
	}
}
.c-benefits-body__img {
	width: 100%;
}
.c-benefits-body__article {
	position: relative;
	padding: 4rem 3rem;
	background-color: #fff;
	z-index: 1;
}
.c-benefits-body__article-title {
	font-family: $fontBlack;
	font-size: 2.6rem;
	line-height: 3.6rem;
	color: $textColor;
	margin-bottom: 2.4rem;
}
.c-benefits-body__article-par {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
}
.c-benefits-footer {
	
		&::after {
		display: table;
		content: '';
		clear: both;
	}
}

.c-benefits-footer__aside {
	@include span(6 of 12 last);
	margin-bottom: 13.2rem;
}
.c-benefits-footer__aside-par {
	font-family: $fontReg;
	font-size: 2.4rem;
	line-height: 3.6rem;
}
.c-benefits-footer__article-img-wrap {
	@include span(10 of 12);
	margin-left: span(1 wide);
}
.c-benefits-footer__article {
	@include span(4.5 of 12 last);
	margin-right: span(1.4);
	padding: 36px 30px;
	background-color: #fff;
	margin-top: -36rem;
}
.c-benefits-footer__article-title {
	font-family: $fontBlack;
	font-size: 2.6rem;
	line-height: 3.6rem;
	color: $textColor;
	margin-bottom: 22px;
}
.c-benefits-footer__article-par {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
}

.l-info {
	margin-bottom: 13.2rem;
}
.c-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.c-info__item {
	width: span(3);
	
	&:last-of-type {
		.c-info__item-par {
			&:last-of-type {
				text-decoration: underline;
				margin-top: 26px;
			}
		}
	}
}
.c-info__item-title {
	font-family: $fontBlack;
	font-size: 2rem;
	line-height: 3.2rem;
	margin-bottom: 30px;
}
.c-info__item-list {
	li {
		font-family: $fontReg;
		font-size: 1.6rem;
		line-height: 2.6rem;
	}
}
.c-info__item-attention {
	display: inline-block;
	font-family: $fontBold;
	text-decoration: underline;
	margin-top: 30px;
}
.c-info__item-par {
	font-family: $fontReg;
	font-size: 1.6rem;
	line-height: 2.6rem;
}


//footer
.l-footer {
	height: 140px;
	background-color: #000;
}
.c-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	align-items: center;
}
.c-footer__copyright,
.c-developer {
	font-family: $fontReg;
	font-size: 1.4rem;
	line-height: 2.6rem;
	color: #fff;
	opacity: 0.4;
}
.c-developer__link {
	text-decoration: underline;
}
.c-developer__special {
	color: rgba(#ff0000, 1);
}

.l-counter {
	padding-top: 10rem;
	margin-bottom: 16.2rem;
}
.c-counter {
	text-align: center;
}
.c-counter__amount {
	font-size: 10rem;
	margin: 0 16px;
}

.l-prefooter {
	padding-top: 6rem;
	margin-bottom: 13.2rem;
}
.c-prefooter {
	@include span(6 of 12);
	margin-left: span(3 wide);
	text-align: center;
	border: 10px solid rgba(0,0,0,1);
	border-top: none;
	border-bottom: none;
	.c-prefooter__title {
		margin-bottom: 20px;
		font-size: 3.2rem;
		line-height: 4.2rem;
	}
	.c-prefooter__number {
		color: rgba(0,0,0,1);
		font-size: 2.4rem;
	}
}




































