@media all and (max-width: 768px) {
	
	.js-toRight {
		animation-name: toRight;
		animation-duration: 1s;
	}
	.js-toLeft {
		animation-name: toLeft;
		animation-duration: 1s;
	}
	.js-wowAppearance {
		animation-name: wowAppearance;
		animation-duration: 1s;
	}
	.js-simpleAppearance {
		animation-name: simpleAppearance;
		animation-duration: 1s;
		animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}
	.js-opacityAppearance {
		animation-name: opacityAppearance;
		animation-duration: 2s;
	}
	.js-toTop {
		animation-name: toTop;
		animation-duration: 1s;
		animation-timing-function: ease-out;
	}
	
	
	.c-pro-slider__descr {
		margin-top: 100px;
	}
	
	.c-about__aside {
		margin-left: span(1 wide);
	}
	
	.c-about__list {
		@include span(5 of 5);
	}
	
	.c-about__img-wrap {
		@include span(4 of 12 last);
	}
}

@media all and (max-width: 425px) {
	
	html {
		font-size: 8px;
	}
	
	body {
		
		.l-container {
			padding-left: 4px;
			padding-right: 4px;
		}
		
	}
	
	.c-pro-slider__contols {
		@include span(break isolate 1 at 10 of 12);
		margin-top: -30rem;
	}
	.c-pro-slider__contols .c-pro-slider__contols-wrap:hover .c-pro-slider__arrow {
		border-color: black;
	}
	
	.l-header {
		margin-bottom: 2rem;
	}
	.c-header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 100%;
	}
	.c-header__logo {
		margin: 0 auto;
	}
	.c-header__wrap-btns {
		justify-content: space-around;
	}
	.c-header__btn-callback {
		padding: 0 0 0 50px;
	}
	.c-header__btn-order {
		width: 128px;
		padding-left: 54px;
		&::before {
			left: 14px;
		}
	}
	
	.c-main-slider__img {
		@include span(8);
		margin-left: span(2.6);
	}
	
	.c-pro-slider__descr {
		@include span(break 8);
		margin-left: span(2.6);
		margin-top: 0;
	}
	
	.c-about__img-wrap {
		@include span(break first 8);
		margin-left: span(2.6);
		margin-top: 0;
	}
	
	.c-about__aside {
		@include span(break first 8);
		margin-left: span(2.6);
	}
	
	.c-know-how__left-aside,
	.c-know-how__right-aside {
		@include span(break first 8);
		margin-left: span(2.6);
	}
	
	.c-frizione__aside {
		@include span(break first 10 wider);
		margin-left: span(1);
		margin-top: 140px;
		margin-bottom: 0;
	}
	.c-technology__img-wrap,
	.c-technology__aside {
		@include span(break first 10 wider);
		margin-left: span(1);
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.c-benefits-header {
		flex-direction: column;
	}
	.c-benefits-header__item {
		width: span(10 wider);
		margin-left: span(1);
		margin-bottom: 40px;
	}
	.c-benefits-body {
		margin-bottom: 0;
	}
	.c-benefits-body__item {
		@include span(break first 10 wider);
		margin-left: span(1);
		&:nth-of-type(1) {
			margin-bottom: 40px;
			.c-benefits-body__article {
				@include span(12 wider);
				margin-left: span(0);
				margin-top: -16rem;
			}
		}
		&:nth-of-type(2) {
			.c-benefits-body__article {
				@include span(12 wider);
				margin-left: span(0);
				margin-top: 0;
			}
		}
	}
	
	.c-info {
		flex-direction: column;
	}
	.c-info__item {
		@include span(break 10 wider);
		margin-left: span(1);
		margin-bottom: 50px;
	}
	
	.c-footer {
		flex-direction: column;
		justify-content: center;
	}
	
	.l-prefooter {
		padding-top: 0;
	}
	.c-prefooter {
		@include span(10 of 12);
		margin-left: span(1 wide);
	}
	
}