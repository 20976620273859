//pro-slider START

.c-pro-slider__header {
	@include span(break isolate 5 at 3 of 12);
	margin-bottom: 6rem;
}

.c-pro-slider__imgs {
	@include span(break isolate 6 at 0 of 12);

	.c-pro-slider__item-img {
		width: 100%;
	}
	.show {
		animation-name: show;
		animation-duration: 2s;
	}
	.hide {
		animation-name: hide;
		animation-duration: 2s;
	}
}
.c-pro-slider__descr {
	@include span(isolate 5.4 at 7.5 of 12 nest);
	display: block;
	position: relative;
	overflow: hidden;
	height: 260px;
	margin-top: 170px;
	background-color: white;


	.c-pro-slider__slide {
		position: absolute;
		display: inline-block;
		width: 100%;
		height: 100%;
		box-sizing: border-box;	

		.c-pro-slider__slide-header {
			position: relative;
			font-family: $fontReg;
			font-size: 1.6rem;
			line-height: 1.6rem;
			margin-top: 0;
			margin-bottom: 4.4rem;
			color: $textColor;
			&::after {
				content: '';
				position: absolute;
				display: inline-block;
				width: 54px;
				height: 4px;
				background-color: #000;
				left: 0;
				bottom: -22px;
			}
		}
		.c-pro-slider__slide-par {
			font-family: $fontBlack;
			font-size: 3.4rem;
			line-height: 5rem;
			font-weight: bold;
		}
		.c-pro-slider__slide-link {
			position: absolute;
			bottom: 20px;
			color: rgba($textColor,1);			
			font-family: $fontReg;
			font-size: 1.7rem;
			z-index: 10;
			&:before {
				content: '';
				position: absolute;
				bottom: 0px;
				display: block;
				width: 100%;
				height: 20px;
				background-color: rgba($themeColor,1);
				z-index: -1;				
				transform: scaleX(0);
				transform-origin: 50%;
				transition: transform 0.5s ease 0s;
			}
		}
		.c-pro-slider__slide-link:hover {
			color: rgba(255,255,255,1);
			&:before {
				transform: scaleX(1);
			}
		}
	}

	.show {
		animation-name: show;
		animation-duration: 2.6s;
	}
	.hide {
		animation-name: hide;
		animation-duration: 2.6s;
	}
}
.c-pro-slider__contols {
	@include span(break isolate 1 at 7.5 of 12);
	display: flex;
	justify-content: space-around;		
	margin-top: -9rem;

	.c-pro-slider__contols-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		text-align: center;
		vertical-align: middle;

		&:hover {
			cursor: pointer;
			background-color: rgba($textColor, 0.3);

			.c-pro-slider__arrow {
				border-color: white;
			}
		}
	}

	.c-pro-slider__arrow {
		display: inline-block;
		width: 18px;
		height: 18px;
		border: 2px solid $textColor;
		transform: rotate(45deg);
		cursor: pointer;
	}
	.c-pro-slider__arrow-back {
		border-top: none;
		border-right: none;
	}
	.c-pro-slider__arrow-next {
		border-bottom: none;
		border-left: none;
	}
}


//MODAL START
.c-modal-call {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Sit on top */
	padding-top: 6rem; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */	
	
	.c-modal-call__content {		
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
	}
	.c-modal-call__header {
    padding: 30px 16px;
    background-color: $themeColor;
    color: white;
		.c-modal-call__title {
			font-family: $fontBlack;
			font-size: 2em;
			text-align: center;
			text-transform: uppercase;
		}
	}	
	.c-modal-call__body {
		color: black;
		padding: 40px 16px 20px;
		h2 {
			padding: 20px 0;
		}
		p {
			padding: 20px 0;
		}
		.c-modal-call__form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			label {
				font-size: 1.4rem;
				margin-bottom: 4px;	
				text-transform: uppercase;
			}
			input {
				margin-bottom: 30px;
				width: 600px;
				padding: 10px;
			}
			textarea {
				width: 600px;
				resize: vertical;
				padding: 10px
			}
			input[type="file"] {
				width: auto;
			}
			input[type="submit"] {
				width: 200px;		
				margin: 40px 0;
				padding: 16px;
				color: #fff;
				background-color: $themeColor;
				font-family: $fontReg;
				font-size: 1.6rem;
				line-height: 2.6rem;
				text-transform: uppercase;
				font-weight: bold;
				box-sizing: content-box;
				cursor: pointer;
				font-family: $fontReg;
				font-size: 1.4rem;
			}
			select {
				margin-bottom: 30px;
				width: 200px;
				box-sizing: content-box;
				padding: 10px;
			}

		}
	}	
	.c-modal-call__footer {
    padding: 2px 16px;
    background-color: $themeColor;
    color: white;
		.c-modal-call__footer-title {
			text-align: center;
			font-size: 1em;
			font-family: $fontBlack;
			font-weight: normal;
		}
	}

	/* The Close Button */
	.c-modal-call__btn-close {
    color: white;
    float: right;
    font-size: 3rem;
		line-height: 3rem;
    font-weight: bold;
	}	

	.c-modal-call__btn-close:hover,
	.c-modal-call__btn-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
		background-color: #fff;
	}
}


/*  Preloader Containers */
.preloader.full-width {
  width: 100%;
  height: 120px;
  margin-left: 0px;
  margin-right: 0px;
}

.preloader {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 45%;
  margin: 5% auto; 
  position: relative;
}

.description {
  position: absolute;
  bottom: 10px;
  left: 12px;
}

.c-preloader {
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


/* Preloader #1 */

.c-preloader__dots {
  height: 50px;
  width: 80px;
	text-align: center;
}

.c-preloader__dot {
  display: inline-block;
  background: $themeColor;
  height: 8px;
  width: 8px;
  opacity: 0.3;
  border-radius: 50%;
  animation: moveit 1.8s infinite;
	margin-right: 4px;
}

.c-preloader__dot:nth-child(2) {
  animation-delay: .15s;
}

.c-preloader__dot:nth-child(3) {
  animation-delay: .3s;
}

.c-preloader__dot:nth-child(4) {
  animation-delay: .45s;
}

.c-preloader__dot:nth-child(5) {
  animation-delay: .6s;
}

@keyframes moveit {
  0% {
    transform: translateY(0px);
  }
  35% {
    transform: translateY(0px);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.8;
  }
  70% {
    transform: translateY(3px);
    opacity: 0.8;
  }
  85% {
    transform: translateY(-3px);
  }
}