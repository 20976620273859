@font-face {
	font-family: 'LatoBlack';
	src: url(../fonts/Lato-Black.eot);
	src: local('☺'), 
			 url('../fonts/Lato-Black.woff') format('woff'),
			 url('../fonts/Lato-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'LatoBold';
	src: url(../fonts/LatoBold.eot);
	src: local('☺'), 
			 url('../fonts/LatoBold.woff') format('woff'),
			 url('../fonts/LatoBold.ttf') format('truetype'),
			 url('../fonts/LatoBold.svg') format('svg');
}
@font-face {
	font-family: 'LatoRegular';
	src: url(../fonts/LatoRegular.eot);
	src: local('☺'), 
			 url('../fonts/LatoRegular.woff') format('woff'),
			 url('../fonts/LatoRegular.ttf') format('truetype'),
			 url('../fonts/LatoRegular.svg') format('svg');
}
@font-face {
	font-family: 'GothamProBlack';
	src: url(../fonts/GothamProBlack.eot);
	src: local('☺'), 
			 url('../fonts/GothamProBlack.woff') format('woff'),
			 url('../fonts/GothamProBlack.ttf') format('truetype'),
			 url('../fonts/GothamProBlack.svg') format('svg');
}