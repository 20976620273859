.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(../img/flags/#{$country}.svg);
  }
}

@include flag-icon(it);
@include flag-icon(ru);